import Dialog from "./Dialog";

const Prompt = ({ placeholder, on_submit, on_abort }) => {

    let input_id = "input_" + Math.random() + Math.random();

    const submit = () => {
        on_submit(document.getElementById(input_id).value);
    }

    const dialog_content = (
        <>
            <input type="text" placeholder={placeholder} id={input_id} />
            <button onClick={submit}>submit</button>
        </>
    );

    return (
        < Dialog content={dialog_content} close_dialog={on_abort} />
    );
}

export default Prompt;