

import ICON_CHECKMARK from "../../assets/checkmark.svg";
import ICON_READY from "../../assets/ready.svg";
import ICON_FAILURE from "../../assets/failure.svg";
import ICON_LOADING from "../../assets/loading.svg";
import ICON_EMPTY from "../../assets/empty.png";

const Transaction = ({ title, onClick, state }) => {

    let enabled = state == "ready";
    const className = enabled ? "transaction" : "transaction transaction_disabled";

    let icon = null;
    switch (state) {
        case "succeeded":
            icon = ICON_CHECKMARK;
            break;
        case "failed":
            icon = ICON_FAILURE;
            break;
        case "ready":
            icon = ICON_READY;
            break;
        case "loading":
            icon = ICON_LOADING;
            break;
        case null:
            icon = ICON_EMPTY;
            break;
        default:
            throw new Error("invalid state " + state);
    }

    return (
        <div className={className} onClick={enabled ? onClick : null}>
            <img src={icon} className="icon" />
            {title}
        </div>
    );
}

export default Transaction;