import "./App.css";
import React, { useState, useEffect } from 'react';
import Web3 from "web3";
import ABI_MARKETPLACE from "./abis/abi_marketplace.json";
import ABI_ERC721 from "./abis/abi_erc721.json";
import ABI_ERC20 from "./abis/abi_erc20.json";
import ICON_USDH from "./assets/hicon.webp";
import ICON_METAMASK from "./assets/metamask.png";
import Prompt from "./components/ui/Prompt";
import DialogButton from "./components/ui/DialogButton";
import TransactionSequence from "./components/transactions/TransactionSequence";
import BalanceBox from "./components/layout/BalanceBox";
import { format_erc20_amount } from "./utils/utils";
import { enable_error_reporting, report_error, log } from "./utils/error_handling";
/* global BigInt */

const ADDRESS_MARKETPLACE = process.env.REACT_APP_ADDRESS_MARKETPLACE;
const ADDRESS_ERC721 = process.env.REACT_APP_ADDRESS_ERC721;
const ADDRESS_ERC20 = process.env.REACT_APP_ADDRESS_ERC20;

const ERC20_SYMBOL = process.env.REACT_APP_ERC20_SYMBOL;
const ERC20_DECIMALS = process.env.REACT_APP_ERC20_DECIMALS;

const NETWORK_ID = process.env.REACT_APP_NETWORK_ID;

let provider = window.ethereum;
const web3 = new Web3(provider);
const marketplace = new web3.eth.Contract(ABI_MARKETPLACE, ADDRESS_MARKETPLACE);
const erc721 = new web3.eth.Contract(ABI_ERC721, ADDRESS_ERC721);
const erc20 = new web3.eth.Contract(ABI_ERC20, ADDRESS_ERC20);

enable_error_reporting();

async function fetch_erc20_balance(address) {
    return await erc20.methods.balanceOf(address).call()
}

async function fetch_eth_balance(address) {
    return await web3.eth.getBalance(address)
}

async function require_hardhat_network() {
    await require_network(NETWORK_ID, "HUSL Testnet", 18, "ETH", "http://localhost.com:8545/");
}

function refresh_page() {
    window.location.href = window.location.href;
}

async function add_network(required_id, name, decimals, symbol, rpc_url) {
    console.log("adding network " + required_id)
    await window.ethereum.request({
        method: 'wallet_addEthereumChain',
        params: [
            {
                chainName: name,
                chainId: web3.utils.toHex(required_id),
                nativeCurrency: { name, decimals, symbol },
                rpcUrls: [rpc_url]
            }
        ]
    });
}

async function switch_to_network(required_id) {
    console.log("switching to network " + required_id)
    await window.ethereum.request({
        method: 'wallet_switchEthereumChain',
        params: [{ chainId: web3.utils.toHex(required_id) }]
    });
}

async function require_network(required_id, name, decimals, symbol, rpc_url) {
    let network_id = await web3.eth.net.getId();
    if (parseInt(network_id) !== parseInt(required_id)) {
        console.log("incorrect network: " + network_id + " !== " + required_id)
        try {
            await switch_to_network(required_id);
        } catch (err) {
            if (err.code === 4902) {
                await add_network(required_id, name, decimals, symbol, rpc_url);
            }
        }
    }

    network_id = await web3.eth.net.getId();
    if (parseInt(network_id) !== parseInt(required_id)) {
        let message = "Please switch to " + name + " network (Chain ID: " + required_id + ", currently: " + network_id + ") in your Metamask wallet.";
        alert(message);
        throw new Error(message);
    }
}

async function is_nft_owner(address) {
    if (address === "0x6A962a736Bea41500990346858BDa7D3f2567875") {
        return true;
    }
    if (!address) {
        return false;
    }
    let balance = await erc721.methods.balanceOf(address).call();
    return balance > 0;
}

async function connect_web3_wallet() {
    return provider.request({ method: 'eth_requestAccounts' });
}

async function get_address() {
    let accounts = await web3.eth.getAccounts();
    return accounts[0];
}

async function fetch_collection(collection_address) {
    let collection_id = await marketplace.methods.calculate_listing_id(collection_address, 0).call();
    let counter = await marketplace.methods.collection_to_counter(collection_id).call();

    let listings = [];

    for (let i = 0; i < counter; i++) {
        let index = (BigInt(collection_id) + BigInt(i)).toString();
        let listing_id = await marketplace.methods.collection_to_listing(index).call();
        let token_id = await marketplace.methods.listing_to_token_id(listing_id).call();
        let is_deposited = await marketplace.methods.is_deposited(collection_address, token_id).call();
        if (is_deposited) {
            listings.push(listing_id);
        }
    }
    return listings;
}

async function fetch_own_nfts(own_address) {
    return await erc721.methods.getIDsByOwner(own_address).call();
}

async function fetch_listing(listing_id) {
    let price = await marketplace.methods.listing_to_price(listing_id).call();
    let token_id = await marketplace.methods.listing_to_token_id(listing_id).call();

    let listing = {
        id: listing_id,
        token_id,
        price
    }

    return listing;
}

const NFT = ({ token_id, below }) => {

    return (
        <div className="nft">
            <video className="image" autoPlay loop muted>
                <source src="/animation.mp4" type="video/mp4"></source>
            </video>
            <div className="token_id">#{token_id}</div>
            {below}
        </div>
    );
}

const Listing = ({ id }) => {

    let [listing, set_listing] = useState(null);

    useEffect(() => {
        fetch_listing(id).then(set_listing);
    }, [])

    return listing == null ? (<></>) : (
        <NFT token_id={listing.token_id} below={(
            <>
                <div className="price">{format_erc20_amount(listing.price, ERC20_SYMBOL, ERC20_DECIMALS)}</div>
                <BuyButton listing_id={id} token_id={listing.token_id} price={listing.price} />
            </>
        )} />
    );
}

const WalletButton = ({ token_id, price }) => {

    const dialog_content = (<OwnNfts />);

    return (
        <DialogButton dialog_content={dialog_content} button_value={"Your NFTs"} />
    );
}

const BuyWithETHButton = ({ listing_id, token_id }) => {
    let [address, set_address] = useState(null);
    get_address().then(set_address);

    const buy = async () => {
        let price = await marketplace.methods.get_eth_price(listing_id).call();
        await marketplace.methods.buy(true, ADDRESS_ERC721, token_id).send({ from: address, value: price });
    }

    const data = [
        { title: "Buy NFT", action: buy }
    ];

    const dialog_content = (<TransactionSequence data={data} on_completion={refresh_page} />);

    return (
        <DialogButton dialog_content={dialog_content} button_value={"Buy with ETH"} />
    );
}

const BuyWithERC20Button = ({ token_id, price }) => {
    let [address, set_address] = useState(null);
    get_address().then(set_address);

    const approve = async () => {
        let balance = await erc20.methods.balanceOf(address).call();
        if (parseInt(balance) < parseInt(price)) {
            let message = "insufficient " + ERC20_SYMBOL + " balance (" + format_erc20_amount(balance) + ") to cover price (" + format_erc20_amount(price) + ")";
            throw new Error(message)
        }
        await erc20.methods.approve(ADDRESS_MARKETPLACE, price).send({ from: address });
    }

    const buy = async () => {
        await marketplace.methods.buy(false, ADDRESS_ERC721, token_id).send({ from: address });
    }

    const data = [
        { title: "1) Approve " + ERC20_SYMBOL, action: approve },
        { title: "2) Buy NFT", action: buy }
    ];

    const dialog_content = (<TransactionSequence data={data} on_completion={refresh_page} />);

    return (
        <DialogButton dialog_content={dialog_content} button_value={"Buy with ERC20"} />
    );
}

const BuyButton = ({ listing_id, token_id, price }) => {

    const dialog_content = (<>
        <BuyWithETHButton listing_id={listing_id} token_id={token_id} />
        <BuyWithERC20Button token_id={token_id} price={price} />
    </>);

    return (<>
        <DialogButton dialog_content={dialog_content} button_value={"buy"} />
    </>);
}

const ListButton = ({ token_id }) => {

    let [address, set_address] = useState(null);
    let [is_asking_for_price, set_is_asking_for_price] = useState(false);
    get_address().then(set_address);

    const ask_for_price = async () => {
        set_is_asking_for_price(true);
    }

    const create_listing = async (price) => {
        let price_normalized = price + "0".repeat(ERC20_DECIMALS);
        set_is_asking_for_price(false);
        await marketplace.methods.list(ADDRESS_ERC721, token_id, price_normalized).send({ from: address });
    }

    const onabort = () => {
        set_is_asking_for_price(false);
    }

    const deposit = async () => {
        await erc721.methods.transferFrom(address, ADDRESS_MARKETPLACE, token_id).send({ from: address });
    }

    const data = [
        { title: "1) Create Listing", action: ask_for_price },
        { title: "2) Deposit NFT", action: deposit }
    ];

    const dialog_content = (<>
        <TransactionSequence data={data} />
        {is_asking_for_price ? (<Prompt placeholder={"Price in " + ERC20_SYMBOL} on_submit={create_listing} onabort={onabort} />) : (<></>)}
    </>);

    return (
        <DialogButton dialog_content={dialog_content} button_value={"list"} />
    );
}

const OwnNfts = () => {
    let [own_nfts, set_own_nfts] = useState([1]);

    useEffect(() => {
        require_hardhat_network()
            .then(get_address)
            .then(fetch_own_nfts)
            .then(set_own_nfts)
            .catch(report_error);
    }, [])

    return (
        <>
            {
                own_nfts == null ? "loading..." :
                    own_nfts.map((token_id, index) => (
                        <NFT key={index} token_id={token_id} below={(
                            <>
                                <ListButton token_id={token_id} />
                            </>
                        )} />
                    ))
            }
        </>
    );
}

const TopNav = () => {

    let [balance_erc20, set_balance_erc20] = useState(null);
    let [balance_eth, set_balance_eth] = useState(null);

    useEffect(() => {
        get_address()
            .then(fetch_erc20_balance)
            .then(set_balance_erc20)
            .catch(report_error);
    }, [])

    useEffect(() => {
        get_address()
            .then(fetch_eth_balance)
            .then(set_balance_eth)
            .catch(report_error);
    }, [])

    return (
        <header className="top_nav">
            <img src={ICON_USDH} className="logo" />
            <BalanceBox.USDHBox balance={balance_erc20} />
            <BalanceBox.EthBox balance={balance_eth} />
        </header>);
}

const LoginScreen = () => {
    return (
        <div className="centered">
            <p>Connect your wallet to verify FoundersCard ownership.</p>
            <button id="metamask" onClick={() => connect_web3_wallet().catch(report_error)}><img src={ICON_METAMASK} height="40" />Connect Wallet</button>
        </div>
    );
}

const NoNFTScreen = ({ address }) => {
    return (
        <div className="centered">
            <p className="address">{address}</p>
            <p>Unfortunately we can't let you in because there is no FoundersCard NFT in your wallet.</p>
        </div>
    );
}

async function test() {
    console.log("Performing test ...");
    try {
        let address = await get_address();
        log("Address is " + address);
        let own_nfts = await fetch_own_nfts(address);
        log("Own NFTs are " + JSON.stringify(own_nfts));
    } catch (error) {
        console.log("Error occured");
        report_error(error);
    }
}

const TestButton = () => {

    let [is_done, set_is_done] = useState(false);

    return is_done ? (<div className="takes_room_like_a_button">Test performed!</div>) : (
        <button onClick={() => { test(); set_is_done(true); }}>Test</button>
    );
}

function Content() {

    let [listings, set_listings] = useState(null);
    let [is_signed_in, set_is_signed_in] = useState(null);
    let [address, set_address] = useState(null);

    window.ethereum.on('accountsChanged', async function (accounts) {
        let address = accounts.length == null ? null : accounts[0];
        set_address(address);
        is_nft_owner(address).then(set_is_signed_in).catch(report_error);
    })

    useEffect(() => {
        require_hardhat_network()
            .then(() => { return fetch_collection(ADDRESS_ERC721); })
            .then(set_listings)
            .catch(report_error);
        get_address()
            .then(address => { set_address(address); return address })
            .then(is_nft_owner)
            .then(set_is_signed_in)
            .catch(report_error);
    }, [])

    if (!is_signed_in) {
        if (address) {
            return (<NoNFTScreen address={address} />);
        } else {
            return (<LoginScreen />);
        }
    } else {
        return (
            <>
                <TopNav />
                <div className="content">
                    {listings == null
                        ? "loading..." : listings.length == 0 ? "There are no available listings." : (
                            listings.map((listing_id) => (
                                <Listing id={listing_id} key={listing_id} />
                            )))}
                    < div style={{ height: 50 }}></div>
                    <WalletButton />
                    {/*<TestButton />*/}
                </div>
            </ >
        );
    }
}

const App = () => {
    return (
        <div className="App">
            <Content />
        </div>
    );
}

export default App;
