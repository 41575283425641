import { useState } from "react";
import Transaction from "./Transaction";


const TransactionSequence = ({ data, on_completion }) => {

    let [is_completed, set_is_completed] = useState(false);
    let [states, set_states] = useState(data.map((_, index) => index == 0 ? "ready" : null));

    function set_state(index, state) {
        let new_states = JSON.parse(JSON.stringify(states));
        new_states[index] = state;
        set_states(new_states);
    }

    if(!is_completed && data.every(e => e === "succeeded")) {
       // on_completion();
        set_is_completed(true);
    }

    return (
        <>
            {data.map(({ title, action }, index) => {
                let action_and_proceed = async () => {
                    try {
                        set_state(index, "loading")
                        await action();
                        set_state(index, "succeeded")
                    } catch (error) {
                        set_state(index, "failed")
                        alert("ERROR: " + error.message);
                        throw error;
                    }
                }

                let state = states[index];
                if (index > 0 && state == null && states[index - 1] == "succeeded") {
                    state = "ready";
                }

                return (
                    <Transaction key={index} title={title} onClick={action_and_proceed} state={state} />
                );
            })}
        </>
    );
}

export default TransactionSequence;