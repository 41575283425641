import { useState } from "react";
import Dialog from "./Dialog";

const DialogButton = ({ button_value, dialog_content }) => {

    let [is_dialog_shown, set_is_dialog_shown] = useState(false);

    const close_dialog = () => set_is_dialog_shown(false);

    return (<>
        <button onClick={() => set_is_dialog_shown(true)}>{button_value}</button>
        {is_dialog_shown ? (
            <Dialog content={dialog_content} close_dialog={close_dialog} />
        ) : (<></>)}
    </>);
}

export default DialogButton;