import { format_erc20_amount } from "../../utils/utils";
import ICON_USDH from "../../assets/hicon.webp";
import ICON_ETH from "../../assets/ethereum.png";
const ERC20_SYMBOL = process.env.REACT_APP_ERC20_SYMBOL;
const ERC20_DECIMALS = process.env.REACT_APP_ERC20_DECIMALS;

const BalanceBox = ({ icon, balance, symbol, decimals, show_decimals = 2 }) => {

    return (<div class="balance">
        <img src={icon} className="logo" /> Balance: {balance == null ? "loading ..." : format_erc20_amount(balance, symbol, decimals, show_decimals)}</div>
    );
}

const USDHBox = ({balance}) => {
    return <BalanceBox icon={ICON_USDH} balance={balance} symbol={ERC20_SYMBOL} decimals={ERC20_DECIMALS} />;
}

const EthBox = ({balance}) => {
    return <BalanceBox icon={ICON_ETH} balance={balance} symbol="ETH" decimals={18} show_decimals={4} />;
}

export default { BalanceBox, USDHBox, EthBox };