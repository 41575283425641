const BACKEND_URL = process.env.REACT_APP_BACKEND_URL;

var error_reporting_ever_failed_before = false;

function submit_error_report(error_report) {
    fetch(BACKEND_URL + '/log', {
        method: 'POST',
        headers: {
            'Accept': 'application/json',
            'Content-Type': 'application/json'
        },
        body: JSON.stringify(error_report)
    })
        .then(res => res.json())
        .then(res => {
            if (!res.success) {
                console.log("An error occurred and we were not able to report it: " + JSON.stringify(error_report));
                console.log(res.error);
                if (!error_reporting_ever_failed_before) {
                    error_reporting_ever_failed_before = true;
                    alert("An error occurred and we were not able to automatically report it. Please check your browser console and share its contents with us.");
                }
            }
        })
        .catch(error => {
            console.log("Error when trying to report other error: " + error.message);
        })
}

function report_error(error) {
    console.log("reporting error ...")
    console.log(error.stack);
    let error_report = {
        type: "error",
        message: error.stack
    };
    submit_error_report(error_report);
}

function log(message) {
    console.log(message);
    let error_report = {
        type: "log",
        message
    };
    submit_error_report(error_report);
}

function enable_error_reporting() {
    window.onerror = function (message, url, line) {
        console.log("reporting error ...")
        let error_report = {
            type: "error",
            message,
            url,
            line
        };
        submit_error_report(error_report);
    }

    window.addEventListener("unhandledrejection", e => {
        console.log("reporting error ...")
        console.log(e);
        let error_report = {
            type: "error",
            message: e.reason.stack
        };
        submit_error_report(error_report);
    });
}

export { report_error, enable_error_reporting, log };