
const Dialog = ({ content, close_dialog }) => {

    const on_dialog_outer_clicked = (event) => {
        if (event.target.className === "dialog_outer") {
            close_dialog();
        }
    }

    return (
        <div className="dialog_outer" onClick={on_dialog_outer_clicked}>
            <div className="dialog_inner">
                {content}
            </div>
        </div>
    );
}

export default Dialog;